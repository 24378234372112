import { Button, Input, Panel } from 'rsuite';

import { API_URL } from '../Constants';
import axios from 'axios';
import { setToast } from '../slices/appSlice';
import { useDispatch } from 'react-redux';
import {useState} from 'react';

const Torrents = () => {
    return (
      <div className="Torrents">
        <iframe title={"transmission-frame"} src="https://torrent.advoxel.com" style={{width:"100%", height:"100vh"}}></iframe>
      </div>
    );
};
export default Torrents;