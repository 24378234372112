import Appliances from "./pages/Appliances"
import { Message } from 'rsuite';
import Terminal from "./pages/Terminal";
import Shortcuts from "./pages/Shortcuts";
import Torrents from "./pages/Torrents";
import Welcome from "./pages/Welcome";
export const routes = [
    {
      navbar:true,
      path: "/",
      text:"Home",
      element: <Welcome />,
    },
    {
      path: "services/:type?",
      text:"Services",
      element: <Appliances />,
    },
    {
      navbar:true,
      path: "torrents",
      text:"Torrents",
      element: <Torrents />,
    },
    {
      navbar:true,
      path: "shortcuts",
      text:"Shortcuts",
      element: <Shortcuts />,
    },
    {
      navbar:true,
      path: "terminal",
      text:"Terminal",
      element: <Terminal />,
    }
]
export const isMobileDevice = () => {
  if(window.innerWidth <= 700){
    return true;
  } else {
    const userAgent = window.navigator.userAgent.toLowerCase();
    const mobileDevices = /android|webos|iphone|ipad|ipod|blackberry|iemobile|opera mini/;
    return mobileDevices.test(userAgent);
  }
  
}
export const getToasterMessage = (type="info", message) => {
  return <Message showIcon type={type} closable>
    {message}.
  </Message>
}
export const API_URL ="https://home.advoxel.com";