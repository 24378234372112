import { Message, useToaster } from 'rsuite';
import { Outlet, useLocation, useSearchParams } from 'react-router-dom';
import React, { useEffect } from 'react';
import { setDeviceMobile, setLogged, setToast, setToken } from './slices/appSlice';
import { useDispatch, useSelector } from 'react-redux';

import { API_URL } from './Constants';
import NavBar from './components/NavBar';
import axios from 'axios';
import { isMobileDevice } from './Constants';

const App = () => {
  const {token, logged, toast} = useSelector((state) => state.reducers.app);
  const dispatch = useDispatch();
  const [searchParams, setSearchParams] = useSearchParams();
  const toaster = useToaster();
  useEffect(() => {
    const handleResize = () => {
     dispatch(setDeviceMobile(isMobileDevice()));
    };
    handleResize();
    window.addEventListener('resize', handleResize);
    return () => {
        window.removeEventListener('resize', handleResize);
    };
}, [dispatch]);
  useEffect(() => {
      if(toast) {
        toaster.push(  <Message type={toast.icon} showIcon closable>
         {toast.message}
        </Message>, {placement:"bottomStart", duration: 5000, type:toast.type})
      }
  }, [dispatch, toaster, toast]);
  useEffect(() => {
    if (searchParams.get("token")) {
      dispatch(setToken(searchParams.get("token")));
      localStorage.setItem("adv-token", searchParams.get("token"));
      setSearchParams({});
    }
  }, [dispatch, searchParams, setSearchParams]);
  useEffect(() => {
    if (localStorage.getItem("adv-token")) {
      dispatch(setToken(localStorage.getItem("adv-token")));
    }
  }, [dispatch]);
  useEffect(() => {
    if(token) {
      axios.get(`${API_URL}/state`, { headers: { "adv-token": token } }).then((response) => {
        dispatch(setLogged(response.data.logged));
        dispatch(setToast({icon:"info","message":response.data.data}));
      }).catch((error) => {
        dispatch(setToast( { icon: "error", message: error.message } ));
      });
    }
  }, [token,dispatch]);
  return (
    <div className="App" >
      <NavBar appearance="subtle" />
      <Outlet />
    </div>
  );
}
export default App;