import { useLocation, useRouteError } from "react-router-dom";

function ErrorPage() {
    const location = useLocation();
  const error = useRouteError();

  console.log("ErrorPage", location, error);
  return (
    <div className="ErrorPage">
      <div>{error.status}</div>
      <div>{error.statusText}</div>
    </div>
  );
}

export default ErrorPage;
