import { useSelector } from 'react-redux';

const Welcome = () => {
    const {token, logged, toast} = useSelector((state) => state.reducers.app);
    return (
      <div className="Welcome">
        Welcome
        {logged?<div>Logged In</div>:<div>Not Logged In</div>}
      </div>
    );
  }
  
  export default Welcome;
  