import { Nav, Navbar, useToaster } from 'rsuite';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import {filter, map} from 'lodash';
import {routes} from '../Constants';
import ExitIcon from '@rsuite/icons/Exit';
import OthersIcon from '@rsuite/icons/Others';
import SettingHorizontalIcon from '@rsuite/icons/SettingHorizontal';
import {setSection} from '../slices/appSlice';

const NavBar = () => {
  const dispatch = useDispatch(); 
  const navigate = useNavigate();
  const {logged, isMobile,section} = useSelector((state) => state.reducers.app);
  const onSelect = (eventKey) => {
    dispatch(setSection(eventKey));
    navigate(eventKey);
  };
    return (
      <Navbar>
        <Nav onSelect={onSelect} activeKey={section}>
          {map(filter(routes, {navbar:true}), (route, index) => {
            return <Nav.Item key={index} eventKey={route.path} >{route.text}</Nav.Item>
          })}
        </Nav>
        <Nav pullRight>
          {logged?<Nav.Item onClick={()=>{
          }} icon={<SettingHorizontalIcon />}>{isMobile?"":"Account"}</Nav.Item>:<Nav.Item onClick={()=>{
          }} icon={<OthersIcon />}>{isMobile?"":"Login/Register"}</Nav.Item>}
          {logged?<Nav.Item onClick={()=>{
          }}  icon={<ExitIcon />}></Nav.Item>:null}
        </Nav>
      </Navbar>
    );
};

export default NavBar;