import { Button, Input, Panel } from 'rsuite';

import { API_URL } from '../Constants';
import {useState} from 'react';
import axios from 'axios';
import { setToast } from '../slices/appSlice';
import { useDispatch } from 'react-redux';

const Terminal = () => {
   const [output, setOutput] = useState("")
   const [command, setCommand] = useState("")
    const dispatch = useDispatch();
    return (
      <div className="Terminal">
      <Input readOnly style={{border:"1px solid white"}} value={output} rows={25}as={"textarea"}/>
       <Input placeholder="Enter Code" onChange={(data)=>{
           setCommand(data)
        }}/>
        <Button onClick={()=>{
            console.log("Run"); 
            axios({url:`${API_URL}/execute`,method:"post",data:{code:command},headers:{"adv-token":123}}).then((response) => {
                console.log(response.data.output);
                setOutput(response.data.output)
               dispatch(setToast( { icon: "success", message:"Executed" } ));
            }).catch((error) => {
                dispatch(setToast( { icon: "error", message: error.message } ));
            });
        } }>Run</Button>
      </div>
    );
};
export default Terminal;