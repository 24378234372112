import { createSlice } from '@reduxjs/toolkit'
import { isMobileDevice } from '../Constants';
const initialState = {
  logged:false,
  theme:"dark",
  section:"/",
  token:null,
  loading:true,
  toast:null,
  isMobile:isMobileDevice(),
}
export const appSlice = createSlice({
  name: 'appSlice',
  initialState,
  reducers: {
    setLogged: (state,action) => {
      state.logged = action.payload;
    },
    setSection: (state,action) => {
      state.section = action.payload;
    },
    setToken: (state,action) => {
      state.token = action.payload;
    },
    setDeviceMobile: (state,action) => {
      state.isMobile = action.payload;
    },
    setTheme: (state,action) => {
      state.theme = action.payload;
    },
    setToast: (state,action) => {
      state.toast = action.payload;
    },
    setLoading: (state,action) => {
      state.loading = action.payload;
    },
  },
})
const { actions, reducer } = appSlice
export const {setToast,setSection, setTheme,setLogged, setLoading,setToken,setDeviceMobile} = actions
export default reducer;