import 'rsuite/dist/rsuite.min.css';
import './index.css';

import { RouterProvider, createBrowserRouter } from "react-router-dom";

import App from './App';
import ErrorPage from "./pages/ErrorPage";
import { Provider } from "react-redux";
import React from 'react';
import { createRoot } from "react-dom/client";
import {map}from "lodash"
import { routes } from "./Constants";
import {store} from "./store";

const router = createBrowserRouter(
  [
    {
      path: "/",
      element: <App/>,
      errorElement: <ErrorPage/>,
      children: map(routes, (route) => {
        return {
          path: route.path,
          element: route.element
        };
      }),
    }
  ],
  {
    basename: "/",
    future: {
      v7_normalizeFormMethod: true,
    },
  },
);
createRoot(document.getElementById("root")).render(
  
    <Provider store={store}>
        <RouterProvider router={router} />
      </Provider>
  
);
