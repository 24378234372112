import { createSlice } from '@reduxjs/toolkit'
const initialState = {
  showPost:false,
  posts: []
}
export const formSlice = createSlice({
  name: 'postSlice',
  initialState,
  reducers: {
    setPosts: (state, action) => {
      state.posts = action.payload;
    },
    togglePostForm: (state,action) => {
      state.showPost = action.payload;
    },
  },
})

const { actions, reducer } = formSlice
export const {togglePostForm, setPosts,setLikes} = actions
export default reducer